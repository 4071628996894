import * as React from "react";
import {
  Button,
  Card,
  Grid,
  Box,
  Dialog,
  DialogActions,
  TextField,
  Typography,
  Checkbox,
  FormControlLabel,
} from "@mui/material";
import CloudDownloadIcon from "@mui/icons-material/CloudDownload";
import axios from "../../../utils/axios";
import hosteo from "../../../utils/hosteo";
import { useState } from "react";
import * as XLSX from "../../../../node_modules/xlsx/xlsx.js";
import { enqueueSnackbar } from "notistack";
import { HelperText } from "../HelperText.jsx";
import { LoadingButton } from "@mui/lab";
import { Form, FormikProvider, useFormik } from "formik";
import * as Yup from "yup";
import CustomLabel from "../../../theme/CustomLabel.js";

const label = { inputProps: { "aria-label": "Checkbox demo" } };
const tabs = [
  "Farmanexus",
  "Emergencias",
  "Colegio de Farmaceuticos",
  "Beneficiarios y familiares VITAL por zonas",
  "Cantidad de beneficiarios por provincia",
  "Padrón por zona de empresa",
];

export default function PadronCard() {
  const [isDownloading, setIsDownloading] = useState(false);
  const [openDialog, setOpenDialog] = useState(false);
  const [loadingVital, setLoadingVital] = useState(false);
  const [fondoDesempleo, setFondoDesempleo] = useState(false);
  const [altaTemprana, setAltaTemprana] = useState(false);
  const [fondoDesempleoFarmasecs, setFondoDesempleoFarmasecs] = useState(false);
  const [altaTempranaFarmasecs, setAltaTempranaFarmasecs] = useState(false);
  const [fondoDesempleoEmerg, setFondoDesempleoEmerg] = useState(false);
  const [altaTempranaEmerg, setAltaTempranaEmerg] = useState(false);

  const handleOpenDialog = () => {
    setOpenDialog(true);
  };
  const handleCloseDialog = () => {
    setOpenDialog(false);
  };

  const generateAndDownloadExcel = (datos, name) => {
    // Crea una nueva hoja de cálculo de Excel
    let nombre = "Testing";
    switch (name) {
      case 0:
        nombre = "Farmanexus";
        break;
      case 1:
        nombre = "Emergencias";
        break;
      case 2:
        nombre = "Colegio de Farmaceuticos";
        break;
      case 3:
        nombre = `Beneficiarios vital - ${values?.desde}-${values?.hasta}`;
        break;
      case 4:
        nombre = `Cantidad de benef por provincia`;
        break;
      default:
        break;
    }

    const workbook = XLSX.utils.book_new();

    // Crea una nueva hoja de cálculo con datos
    /* const dia = new Date();
    const options = {
      timeZone: "America/Buenos_Aires",
      hour12: false,
      hour: "2-digit",
      minute: "2-digit",
      second: "2-digit",
    };*/

    const worksheet = XLSX.utils.aoa_to_sheet([]);
    XLSX.utils.sheet_add_json(worksheet, datos, { origin: -1 });
    XLSX.utils.book_append_sheet(workbook, worksheet, nombre);
    const excelBuffer = XLSX.write(workbook, {
      bookType: "xlsx",
      type: "array",
    });
    const blob = new Blob([excelBuffer], {
      type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
    });
    const url = URL.createObjectURL(blob);
    const link = document.createElement("a");
    link.href = url;
    link.download = `${nombre}.xlsx`;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  const getBenefsPorProvExcel = (datos) => {
    // Crea una nueva hoja de cálculo de Excel
    const nombre = "Cantidad de benef por provincia";

    const workbook = XLSX.utils.book_new();

    // Crea una nueva hoja de cálculo con datos
    const dia = new Date();
    const options = {
      timeZone: "America/Buenos_Aires",
      hour12: false,
      hour: "2-digit",
      minute: "2-digit",
      second: "2-digit",
    };

    const encabezado = [
      [`Reporte de ${nombre}`],
      [
        `Generado a las ${dia
          .toLocaleTimeString("es-AR", options)
          .slice(0, 5)}hs del ${dia.toLocaleDateString()}`,
      ],
      [""],
    ];

    const columnTitulares = ["cantidadTitulares", "codigo", "provincia"];

    const datosConTitulos = [...encabezado, columnTitulares];

    datos.titulares.forEach((item) => {
      const rowData = [item.cantidadTitulares, item.codigo, item.provincia];
      datosConTitulos.push(rowData);
    });

    const worksheet = XLSX.utils.aoa_to_sheet(datosConTitulos);
    XLSX.utils.book_append_sheet(workbook, worksheet, "titulares");
    // ----------------------------------------------------

    const columnFamiliares = ["cantidadFamiliares", "codigo", "provincia"];

    const datosConTitulos2 = [...encabezado, columnFamiliares];

    datos.familiares.forEach((item) => {
      const rowData = [item.cantidadFamiliares, item.codigo, item.provincia];
      datosConTitulos2.push(rowData);
    });
    const worksheet2 = XLSX.utils.aoa_to_sheet(datosConTitulos2);
    XLSX.utils.book_append_sheet(workbook, worksheet2, "familiares");

    const excelBuffer = XLSX.write(workbook, {
      bookType: "xlsx",
      type: "array",
    });
    const blob = new Blob([excelBuffer], {
      type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
    });
    const url = URL.createObjectURL(blob);
    const link = document.createElement("a");
    link.href = url;
    link.download = `${nombre}.xlsx`;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  const padronZonaEmpExcel = (datos) => {
    // Crea una nueva hoja de cálculo de Excel
    const nombre = "Cantidad de benef por provincia";

    const workbook = XLSX.utils.book_new();

    // Crea una nueva hoja de cálculo con datos
    const dia = new Date();
    const options = {
      timeZone: "America/Buenos_Aires",
      hour12: false,
      hour: "2-digit",
      minute: "2-digit",
      second: "2-digit",
    };

    const encabezado = [
      [`Reporte de ${nombre}`],
      [
        `Generado a las ${dia
          .toLocaleTimeString("es-AR", options)
          .slice(0, 5)}hs del ${dia.toLocaleDateString()}`,
      ],
      [""],
    ];

    const columnTitulares = [
      "Zona",
      "Empresa",
      "Cuit",
      "Razon",
      "N° Benef",
      "Paren",
      "Cuil",
      "NumDoc",
      "Nombre",
    ];

    const datosConTitulos = [...encabezado, columnTitulares];

    datos.benefDatosEmp.forEach((item) => {
      const rowData = [
        item.zona,
        item.empresa,
        item.cuit,
        item.razon,
        item.nbenef,
        item.paren,
        item.cuil,
        item.numdoc,
        item.nombre,
      ];
      datosConTitulos.push(rowData);
    });

    const worksheet = XLSX.utils.aoa_to_sheet(datosConTitulos);
    XLSX.utils.book_append_sheet(workbook, worksheet, "Benef con datos emp");
    // ----------------------------------------------------

    const columnFamiliares = [
      "Cod.Prov",
      "Provincia",
      "Localidad",
      "Razon",
      "Cuit",
      "Cod.Titu",
      "Cod.Fam",
      "Total",
    ];

    const datosConTitulos2 = [...encabezado, columnFamiliares];

    datos.benefPorEmpresa.forEach((item) => {
      const rowData = [
        item.cprov,
        item.provincia,
        item.localidad,
        item.razon,
        item.cuit,
        item.ctitu,
        item.cFam,
        item.total,
      ];
      datosConTitulos2.push(rowData);
    });
    const worksheet2 = XLSX.utils.aoa_to_sheet(datosConTitulos2);
    XLSX.utils.book_append_sheet(
      workbook,
      worksheet2,
      "Beneficiarios por empresa"
    );

    const excelBuffer = XLSX.write(workbook, {
      bookType: "xlsx",
      type: "array",
    });
    const blob = new Blob([excelBuffer], {
      type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
    });
    const url = URL.createObjectURL(blob);
    const link = document.createElement("a");
    link.href = url;
    link.download = `${nombre}.xlsx`;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  const downloadFiles = async (index) => {
    let url = "";
    switch (index) {
      case 0:
        url = `/api/empadronamiento/beneficiarios/getPadronFarmanexus`;
        break;
      case 1:
        url = `/api/empadronamiento/beneficiarios/getPadronEmergencia`;
        break;
      case 2:
        url = `/api/empadronamiento/beneficiarios/getPadronColegioFarmaceuticos`;
        break;
      case 3:
        handleOpenDialog();
        break;
      case 4:
        url = `/api/empadronamiento/beneficiarios/getCantBenefProv`;
        break;
      case 5:
        url = `/api/empadronamiento/beneficiarios/getPadronZonaEmpresa`;
        break;
      default:
        // Declaraciones ejecutadas cuando ninguno de los valores coincide con el valor de la expresión
        break;
    }
    try {
      setIsDownloading(true);
      if (index === 0) {
        const response = await axios.post(`${hosteo}${url}`, {
          fdes: fondoDesempleoFarmasecs ? 1 : 0,
          altaTem: altaTempranaFarmasecs ? 1 : 0,
        });
        generateAndDownloadExcel(response.data, index);
      } else if (index === 1) {
        const response = await axios.post(`${hosteo}${url}`, {
          fdes: fondoDesempleoEmerg ? 1 : 0,
          altaTem: altaTempranaEmerg ? 1 : 0,
        });
        generateAndDownloadExcel(response.data, index);
      } else if (index === 2) {
        const response = await axios.post(`${hosteo}${url}`, {
          fdes: fondoDesempleoFarmasecs ? 1 : 0,
          altaTem: altaTempranaFarmasecs ? 1 : 0,
        });
        generateAndDownloadExcel(response.data, index);
      } else if (index === 4) {
        const response = await axios.get(`${hosteo}${url}`);
        getBenefsPorProvExcel(response.data);
      } else if (index === 5) {
        const response = await axios.get(`${hosteo}${url}`);
        padronZonaEmpExcel(response.data);
      } else {
        const response = await axios.get(`${hosteo}${url}`);
        generateAndDownloadExcel(response.data, index);
      }
    } catch (error) {
      console.log(error);
      enqueueSnackbar(error.message || "Error en la busqueda", {
        variant: "error",
      });
    } finally {
      setIsDownloading(false);
    }
  };

  const validationSchema = Yup.object().shape({
    desde: Yup.number()
      .typeError("Debe ser un número")
      .required("Este campo es requerido"),
    hasta: Yup.number()
      .typeError("Debe ser un número")
      .required("Este campo es requerido"),
  });

  const formik = useFormik({
    enableReinitialize: true,
    validationSchema: validationSchema,
    initialValues: {
      desde: "",
      hasta: "",
    },
    // validationSchema,
    onSubmit: async (values) => {
      try {
        setLoadingVital(true);
        const response = await axios.post(
          `${hosteo}/api/empadronamiento/beneficiarios/getBenefVitalZonas`,
          {
            desde: values.desde,
            hasta: values.hasta,
            fdes: fondoDesempleo ? 1 : 0,
            altaTem: altaTemprana ? 1 : 0,
          }
        );
        setLoadingVital(false);
        generateAndDownloadExcel(response.data, 3);
        setOpenDialog(false);
        formik.resetForm();
        setFondoDesempleo(false);
        setAltaTemprana(false);
      } catch (error) {
        setLoadingVital(false);
        enqueueSnackbar(error.message || "Algo salio mal", {
          variant: "error",
        });
      }
    },
  });
  const { handleSubmit, getFieldProps, touched, errors, values } = formik;

  const handleKeyDown = (event, nextInputId) => {
    if (event.key === "Enter") {
      if (nextInputId === 4) {
      } else {
        document.getElementById(nextInputId + 1).focus();
      }
    }
    console.log(nextInputId + 1);
  };

  return (
    <>
      <Box sx={{ mt: 2 }}>
        <HelperText
          message={
            "Descargara el padron para entregar Farmanexus, Emergencias o al Colegio de Farmaceuticos en los primeros días del mes."
          }
        />
      </Box>
      <Grid
        container
        justifyContent={"center"}
        display={"flex"}
        marginTop={3}
        columnGap={3}
      >
        {tabs?.map((tabPadron, index) => (
          <Grid key={index} md={3} marginTop={3} id={index}>
            <Card
              sx={{
                padding: 10,
                height: 200,
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                flexDirection: "column",
              }}
            >
              {tabPadron === "Beneficiarios y familiares VITAL por zonas" ? (
                <>
                  <Grid textAlign={"center"}>{tabPadron}</Grid>
                  <Grid
                    justifyContent={"center"}
                    display={"flex"}
                    marginTop={3}
                  >
                    <LoadingButton
                      sx={{ textAlign: "center" }}
                      variant="contained"
                      disabled={isDownloading}
                      loading={isDownloading}
                      onClick={() => handleOpenDialog()}
                      startIcon={<CloudDownloadIcon />}
                    >
                      Descargar archivo
                    </LoadingButton>
                  </Grid>
                </>
              ) : (
                <>
                  {tabPadron === "Farmanexus" ? (
                    <>
                      <Grid textAlign={"center"}>{tabPadron}</Grid>
                      <Grid
                        justifyContent={"center"}
                        display={"flex"}
                        marginTop={3}
                      >
                        <LoadingButton
                          sx={{ textAlign: "center" }}
                          variant="contained"
                          disabled={isDownloading}
                          loading={isDownloading}
                          onClick={() => downloadFiles(index)}
                          startIcon={<CloudDownloadIcon />}
                        >
                          Descargar archivo
                        </LoadingButton>
                      </Grid>
                      <Box
                        sx={{
                          display: "flex",
                          justifyContent: "center",
                          mt: 2,
                        }}
                      >
                        <FormControlLabel
                          label="Sin fondo de desempleo"
                          control={
                            <Checkbox
                              checked={fondoDesempleoFarmasecs}
                              onChange={(e) => {
                                setFondoDesempleoFarmasecs(e.target.checked);
                              }}
                              {...label}
                            />
                          }
                        />
                        <FormControlLabel
                          label="Sin alta temprana"
                          control={
                            <Checkbox
                              checked={altaTempranaFarmasecs}
                              onChange={(e) => {
                                setAltaTempranaFarmasecs(e.target.checked);
                              }}
                              {...label}
                            />
                          }
                        />
                      </Box>
                    </>
                  ) : (
                    <>
                      {tabPadron === "Emergencias" ? (
                        <>
                          <Grid textAlign={"center"}>{tabPadron}</Grid>
                          <Grid
                            justifyContent={"center"}
                            display={"flex"}
                            marginTop={3}
                          >
                            <LoadingButton
                              sx={{ textAlign: "center" }}
                              variant="contained"
                              disabled={isDownloading}
                              loading={isDownloading}
                              onClick={() => downloadFiles(index)}
                              startIcon={<CloudDownloadIcon />}
                            >
                              Descargar archivo
                            </LoadingButton>
                          </Grid>
                          <Box
                            sx={{
                              display: "flex",
                              justifyContent: "center",
                              mt: 2,
                            }}
                          >
                            <FormControlLabel
                              label="Sin fondo de desempleo"
                              control={
                                <Checkbox
                                  checked={fondoDesempleoEmerg}
                                  onChange={(e) => {
                                    setFondoDesempleoEmerg(e.target.checked);
                                  }}
                                  {...label}
                                />
                              }
                            />
                            <FormControlLabel
                              label="Sin alta temprana"
                              control={
                                <Checkbox
                                  checked={altaTempranaEmerg}
                                  onChange={(e) => {
                                    setAltaTempranaEmerg(e.target.checked);
                                  }}
                                  {...label}
                                />
                              }
                            />
                          </Box>
                        </>
                      ) : (
                        <>
                          {tabPadron === "Colegio de Farmaceuticos" ? (
                            <>
                              <Grid textAlign={"center"}>{tabPadron}</Grid>
                              <Grid
                                justifyContent={"center"}
                                display={"flex"}
                                marginTop={3}
                              >
                                <LoadingButton
                                  sx={{ textAlign: "center" }}
                                  variant="contained"
                                  disabled={isDownloading}
                                  loading={isDownloading}
                                  onClick={() => downloadFiles(index)}
                                  startIcon={<CloudDownloadIcon />}
                                >
                                  Descargar archivo
                                </LoadingButton>
                              </Grid>
                              <Box
                                sx={{
                                  display: "flex",
                                  justifyContent: "center",
                                  mt: 2,
                                }}
                              >
                                <FormControlLabel
                                  label="Sin fondo de desempleo"
                                  control={
                                    <Checkbox
                                      checked={fondoDesempleoFarmasecs}
                                      onChange={(e) => {
                                        setFondoDesempleoFarmasecs(
                                          e.target.checked
                                        );
                                      }}
                                      {...label}
                                    />
                                  }
                                />
                                <FormControlLabel
                                  label="Sin alta temprana"
                                  control={
                                    <Checkbox
                                      checked={altaTempranaFarmasecs}
                                      onChange={(e) => {
                                        setAltaTempranaFarmasecs(
                                          e.target.checked
                                        );
                                      }}
                                      {...label}
                                    />
                                  }
                                />
                              </Box>
                            </>
                          ) : (
                            <>
                              <Grid textAlign={"center"}>{tabPadron}</Grid>
                              <Grid
                                justifyContent={"center"}
                                display={"flex"}
                                marginTop={3}
                              >
                                <LoadingButton
                                  sx={{ textAlign: "center" }}
                                  variant="contained"
                                  disabled={isDownloading}
                                  loading={isDownloading}
                                  onClick={() => downloadFiles(index)}
                                  startIcon={<CloudDownloadIcon />}
                                >
                                  Descargar archivo
                                </LoadingButton>
                              </Grid>
                            </>
                          )}
                        </>
                      )}
                    </>
                  )}
                </>
              )}
            </Card>
          </Grid>
        ))}
      </Grid>

      <FormikProvider value={formik}>
        <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
          <Dialog
            open={openDialog}
            fullWidth
            maxWidth={"sm"}
            keepMounted
            onClose={handleCloseDialog}
            aria-labelledby="alert-dialog-slide-title"
            aria-describedby="alert-dialog-slide-description"
          >
            <Card
              sx={{
                padding: 3,
                display: "flex",
                flexDirection: "column",
                gap: 2,
              }}
            >
              <Typography variant="h4" sx={{ textAlign: "center", my: 3 }}>
                Beneficiarios y familiares VITAL por zonas
              </Typography>
              <Grid container spacing={2}>
                <Grid item sm={12} md={6}>
                  <TextField
                    fullWidth
                    label={<CustomLabel>Desde</CustomLabel>}
                    sx={{ mb: 1.3 }}
                    {...getFieldProps("desde")}
                    type="number"
                    error={Boolean(touched.desde && errors.desde)}
                    helperText={touched.desde && errors.desde}
                    id={3}
                    onKeyDown={(e) => handleKeyDown(e, 3)}
                  />
                </Grid>
                <Grid item sm={12} md={6}>
                  <TextField
                    fullWidth
                    label={<CustomLabel>Hasta</CustomLabel>}
                    sx={{ mb: 1.3 }}
                    type="number"
                    {...getFieldProps("hasta")}
                    error={Boolean(touched.hasta && errors.hasta)}
                    helperText={touched.hasta && errors.hasta}
                    id={4}
                    onKeyDown={(e) => handleKeyDown(e, 4)}
                  />
                </Grid>
              </Grid>
              <Box sx={{ display: "flex", justifyContent: "center" }}>
                <FormControlLabel
                  label="Sin fondo de desempleo"
                  control={
                    <Checkbox
                      checked={fondoDesempleo}
                      onChange={(e) => {
                        setFondoDesempleo(e.target.checked);
                      }}
                      {...label}
                    />
                  }
                />
                <FormControlLabel
                  label="Sin alta temprana"
                  control={
                    <Checkbox
                      checked={altaTemprana}
                      onChange={(e) => {
                        setAltaTemprana(e.target.checked);
                      }}
                      {...label}
                    />
                  }
                />
              </Box>
              <DialogActions>
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    width: "100%",
                  }}
                >
                  <Button
                    color="inherit"
                    onClick={() => {
                      handleCloseDialog();
                      formik.resetForm();
                    }}
                  >
                    Cerrar
                  </Button>
                  <LoadingButton
                    type="submit"
                    variant="contained"
                    loading={loadingVital}
                    onClick={formik.handleSubmit}
                  >
                    Descargar
                  </LoadingButton>
                </Box>
              </DialogActions>
            </Card>
          </Dialog>
        </Form>
      </FormikProvider>
    </>
  );
}
