import Cabecera from "../../../../components/Cabecera";
import DiscapXProviuncia from "../../../../components/Empadronamiento/CardsReportes/DiscapXProvincia";

const items = [
  { linkTo: "/dashboard/home", nombre: "Home" },
  { linkTo: "/dashboard/empadronamiento", nombre: "Empadronamiento" },
  {
    linkTo:
      "/dashboard/empadronamiento/beneficiarios/discapacitadosPorProvincia",
    nombre: "Reportes de beneficiacios",
  },
];

export default function BenefPorProvincia() {
  return (
    <>
      <Cabecera
        title="Discapacitados por provincia"
        items={items}
        divider={false}
        helmet="Empadronamiento"
      />
      <DiscapXProviuncia />
    </>
  );
}
