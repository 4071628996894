import {
  Button,
  Card,
  Checkbox,
  Grid,
  TextField,
  Typography,
  CircularProgress,
  Container,
  Box,
} from "@mui/material";
import Radio from "@mui/material/Radio";
import { DesktopDatePicker } from "@mui/x-date-pickers/DesktopDatePicker";
import es from "date-fns/locale/es";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import { DataGrid, esES } from "@mui/x-data-grid";
import { useState, useEffect, useRef } from "react";
import Autocomplete from "@mui/material/Autocomplete";
import axios from "../../../utils/axios";
import hosteo from "../../../utils/hosteo";
import { enqueueSnackbar } from "notistack";
import CustomLabel from "../../../theme/CustomLabel";
import { sexo } from "../../../components/Empadronamiento/Beneficiarios/listasABMtitular";
import { useFormik, Form, FormikProvider } from "formik";
import * as Yup from "yup";
import {
  formatDate,
  convertirCuit,
  convertirFechaSend,
} from "../../../utils/formatNumber";
import { ModalAfiliados } from "../ConsultaAfiliado/ModalAfiliados";
import LoadingButton from "@mui/lab/LoadingButton";

export default function ABMFamiliar({ cuilTitular }) {
  const [isLoading, setIsLoading] = useState(false);
  const [open, setOpen] = useState(false);
  const [titulares, setTitulares] = useState([]);
  const [datosBusqueda, setDatosBusqueda] = useState({
    type: "numBenef",
    id: "",
  });
  //------------Estados para datagrid de paginacion --------------------------
  const [pageSize, setPageSize] = useState(25);
  const [currentPage, setCurrentPage] = useState(0);
  //------------ Busqueda --------------------------
  const [tipoBusqueda, setTipoBusqueda] = useState("cuil");
  const [datoBusqueda, setDatoBusqueda] = useState("");
  //------------ Estados de formulario --------------------------
  const [familiares, setFamiliares] = useState([]);
  const [titular, setTitular] = useState(null);
  const [objOptions, setObjOptions] = useState([]);
  const [selectedParentesco, setSelectedParentesco] = useState(null);
  const [selectedTipoDocumento, setSelectedTipoDocumento] = useState(null);
  const [selectedEstado, setSelectedEstado] = useState(null);
  const [selectedSexo, setSelectedSexo] = useState(null);
  const [dateBaja, setDateBaja] = useState(null);
  const [dateAlta, setDateAlta] = useState(null);
  const [discapacitado, setDiscapacitado] = useState(false);
  const [dateNac, setDateNac] = useState(null);
  const [nacion, setNacion] = useState(null);
  const [mBaja, setMBaja] = useState(null);
  const [afiliado, setAfiliado] = useState(null);
  const [loadingButton, setLoadingButton] = useState(false);
  const [formikLoading, setFormikLoading] = useState(false);
  //-----------Guardar Datos-----------------------------------------
  const [guardarId, setGuardarId] = useState(null);
  const [guardarTipo, setGuardarTipo] = useState(null);
  //---------------------- Modificar ---------------------------
  const [modificar, setModificar] = useState(false);
  const [edicion, setEdicion] = useState(null);

  const selectedObj = (value, array) => {
    if (value === "" || !value) {
      return null;
    } else {
      return array?.find((item) => item.id === value);
    }
  };

  const handleEditar = (value) => {
    setEdicion({
      paren: selectedObj(value.paren, objOptions?.parentesco)?.id,
      tdoc: selectedObj(value.tdoc, objOptions?.tipoDocum)?.id,
      nombre: value.nombre,
      estciv: selectedObj(value.estciv, objOptions?.estado)?.id,
      fbaja: convertirFecha(value.fbaja === "0000-00-00" ? null : value.fbaja),
      alta: convertirFecha(value.alta),
      mbaja: value.mbaja
        ? selectedObj(value.mbaja, objOptions?.baja)?.id
        : null,
      ndoc: value.ndoc,
      cuil: value.cuil,
      sexo: selectedObj(value.sexo, sexo)?.id,
      fnac: convertirFecha(value.fnac),
      nacion: selectedObj(value.nacion, objOptions?.nacionalidades)?.id,
      zona: value.zona,
    });
    setModificar(true);
    setSelectedParentesco(selectedObj(value.paren, objOptions?.parentesco));
    setSelectedTipoDocumento(selectedObj(value.tdoc, objOptions?.tipoDocum));
    setFieldValue("nombre", value.nombre);
    setSelectedEstado(selectedObj(value.estciv, objOptions?.estado));
    setDateBaja(
      convertirFecha(value.fbaja === "0000-00-00" ? null : value.fbaja)
    );
    setDateAlta(convertirFecha(value.alta));
    setDiscapacitado(value.discapacitado);
    setFieldValue("ndoc", value.ndoc);
    setSelectedSexo(selectedObj(value.sexo, sexo));
    setDateNac(convertirFecha(value.fnac));
    setFieldValue("zona", value.zona);
    setNacion(selectedObj(value.nacion, objOptions?.nacionalidades));
    setMBaja(selectedObj(value.mbaja, objOptions?.baja));
    setFieldValue("cuil", value.cuil);
  };

  const limpiarCampos = () => {
    setModificar(false);
    setSelectedParentesco(null);
    setFieldValue("nombre", "");
    setSelectedTipoDocumento(null);
    setSelectedEstado(null);
    setDiscapacitado(null);
    setFieldValue("ndoc", "");
    setSelectedSexo(null);
    setDateAlta(null);
    setDateBaja(null);
    setDateNac(null);
    setFieldValue("zona", "");
    setFieldValue("cuil", "");
    setNacion(null);
    setMBaja(null);
    setAfiliado(null);
  };

  //-------------------------------------------------------------------
  const handleBuscar = async (id, type) => {
    setIsLoading(true);
    setFamiliares([]);
    setTitular([]);
    try {
      const response = await axios.post(
        `${hosteo}/api/empadronamiento/beneficiarios/getParientesABM`,
        { id: id, type: type }
      );
      if (response.data?.titular.length > 1) {
        setTitulares(response.data.titular);
        setOpen(true);
      }
      if (response.data?.parientes) {
        setFamiliares(response.data.parientes);
        setTitular(response.data.titular);
      }
    } catch (error) {
      enqueueSnackbar(error.message || "No tienes conexion a internet", {
        variant: "error",
      });
    }
    setGuardarId(id);
    setGuardarTipo(type);
    setDatoBusqueda("");
    setIsLoading(false);
  };

  const handleClose = (id, type) => {
    // setTipoBusqueda(type);
    // setDatoBusqueda(id);
    handleBuscar(id, type);
    setOpen(false);
  };

  const handleBusqueda = (id, type) => {
    handleBuscar(id, type);
    setOpen(false);
  };

  const handlePaginationChange = (params) => {
    if (params.pageSize !== pageSize) {
      setPageSize(params.pageSize);
      setCurrentPage(0);
    } else {
      setCurrentPage(params.page);
    }
  };
  useEffect(() => {
    const fetchListas = async () => {
      try {
        const response = await axios.post(
          `${hosteo}/api/empadronamiento/beneficiarios/getOpcionsFamiliar`
        );
        setObjOptions(response.data);
      } catch (error) {
        enqueueSnackbar(error.message || "No tienes conexión a internet", {
          variant: "error",
        });
      }
    };
    fetchListas();
  }, []);

  function convertirFecha(fechaString) {
    // Crear un objeto Date a partir de la cadena de fecha
    if (!fechaString) {
      return null;
    }
    const fecha = new Date(fechaString);

    // Obtener los nombres de los días y meses en inglés para su posterior formateo
    const diasSemana = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"];
    const meses = [
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "May",
      "Jun",
      "Jul",
      "Aug",
      "Sep",
      "Oct",
      "Nov",
      "Dec",
    ];

    // Obtener los valores de día, mes y año de la fecha
    const diaSemana = diasSemana[fecha.getDay()];
    const mes = meses[fecha.getUTCMonth()];
    const dia = fecha.getUTCDate();
    const año = fecha.getUTCFullYear();

    // Formatear la fecha según el formato deseado
    const fechaFormateada = `${diaSemana} ${mes} ${dia} ${año} 00:00:00 GMT${
      fecha.getTimezoneOffset() > 0 ? "-" : "+"
    }${Math.abs(fecha.getTimezoneOffset() / 60)
      .toString()
      .padStart(2, "0")}:00`;

    return new Date(fechaFormateada);
  }

  function reConvertirFormatoFecha(fechaString) {
    // Crear un objeto de fecha a partir de la cadena de fecha

    let fecha = new Date(fechaString);
    console.log(fecha);

    // Obtener los componentes de la fecha
    let año = fecha.getFullYear();
    let mes = ("0" + (fecha.getMonth() + 1)).slice(-2);
    let dia = ("0" + fecha.getDate()).slice(-2);

    // Formatear la fecha en el nuevo formato
    let fechaFormateada = `${año}-${mes}-${dia}`;

    return fechaFormateada;
  }

  const rowsWithId =
    familiares.length > 0 &&
    familiares?.map((item, index) => ({
      ...item,
      id: item.index ? item.index : index + 1,
    }));

  //--------------------------------------------- columnas -------------------------------------------------

  const columns = [
    {
      field: "action2",
      headerName: "",
      flex: 1,
      minWidth: 100,
      renderCell: (params) => (
        <Button variant="contained" onClick={() => handleEditar(params.row)}>
          Modificar
        </Button>
      ),
    },
    {
      field: "parentesco",
      headerName: "Patent.",
      flex: 1,
      minWidth: 100,
      editable: true,
    },
    {
      field: "tdoc",
      headerName: "T. Doc.",
      type: "number",
      flex: 1,
      minWidth: 100,
      editable: true,
    },
    {
      field: "ndoc",
      headerName: "N° Doc.",
      type: "number",
      flex: 1,
      minWidth: 100,
      editable: true,
    },
    {
      field: "nombre",
      headerName: "Nombre",
      type: "number",
      flex: 1,
      minWidth: 250,
      editable: true,
    },
    {
      field: "sexo",
      headerName: "Sexo",
      type: "number",
      flex: 1,
      minWidth: 80,
      editable: true,
    },
    {
      field: "estadoCivil",
      headerName: "Est. Civil.",
      type: "number",
      flex: 1,
      minWidth: 120,
      editable: true,
    },
    {
      field: "fnac",
      headerName: "F. Nacimiento",
      type: "number",
      flex: 1,
      minWidth: 150,
      editable: true,
      valueFormatter: (params) => formatDate(params.value),
    },
    {
      field: "fbaja",
      headerName: "F. Baja",
      type: "number",
      flex: 1,
      minWidth: 150,
      editable: true,
      valueFormatter: (params) => formatDate(params.value),
    },
    {
      field: "zona",
      headerName: "Zona",
      type: "number",
      flex: 1,
      minWidth: 80,
      editable: true,
    },
    {
      field: "cuil",
      headerName: "Cuil",
      type: "number",
      flex: 1,
      minWidth: 150,
      editable: true,
      valueFormatter: (params) => convertirCuit(params.value),
    },
    {
      field: "alta",
      headerName: "F. Alta",
      type: "number",
      flex: 1,
      minWidth: 150,
      editable: true,
      valueFormatter: (params) => formatDate(params.value),
    },
    {
      field: "nacional",
      headerName: "Nacionalidad",
      type: "number",
      flex: 1,
      minWidth: 100,
      editable: true,
    },
    {
      field: "motivoBaja",
      headerName: "M. Baja",
      type: "number",
      flex: 1,
      minWidth: 150,
      editable: true,
    },
    {
      field: "afiliado",
      headerName: "Afiliado",
      sortable: false,
      flex: 1,
      minWidth: 80,
    },
  ];
  //---------------------Funcion validar edad x parentesco menor de 21----------------------------
  function EdadMenorVeintiuno(fechaNacimiento) {
    const menores21 = [3, 5, 20];
    const fechaNacimientoDate = new Date(fechaNacimiento);
    const fechaActual = new Date();

    // Calcular la diferencia en años
    const edad =
      fechaActual?.getFullYear() - fechaNacimientoDate?.getFullYear();

    // Ajustar la fecha de nacimiento para comparar solo el mes y día
    fechaNacimientoDate?.setFullYear(fechaActual.getFullYear());
    // Verifica si es menor de 21 y si se seleccionó fecha de nacimiento
    return menores21.some((elemento) => {
      if (elemento === values.paren && values.fnac) {
        if (fechaNacimientoDate > fechaActual) {
          // Si la fecha de nacimiento de este año es posterior a la fecha actual, aún no ha cumplido años
          return edad > 21;
        }
        // Si la fecha de nacimiento de este año ya pasó, se considera un año más de edad
        return edad - 1 > 21;
      }
    });
  }
  // ----------------------------- FORMIK ---------------------------------
  const validationSchemaCrear = Yup.object().shape({
    paren: Yup.string().required("Este campo es requerido"),
    tdoc: Yup.string().required("Este campo es requerido"),
    nombre: Yup.string()
      .required("Este campo es requerido")
      .max(45, "El maximo de caracteres es 45"),
    estciv: Yup.string().required("Este campo es requerido"),
    ndoc: Yup.number("Debe ser un nummero")
      .required("Es requerida")
      .test(
        "cuil-match",
        "El número de documento debe coincidir con el CUIL",
        function (value) {
          if (
            this.parent.cuil?.toString().substring(2, 10) === String(value) ||
            !this.parent.cuil?.toString().substring(2, 10)
          ) {
            return true;
          } else {
            return false;
          }
        }
      ),
    cuil: Yup.number()
      .required("Este campo es requerido")
      .max(99999999999, "El CUIL debe tener como maximo 8 digitos")
      .min(11111111111, "El CUIL debe tener como minimo 7 digitos"),
    sexo: Yup.string().required("Este campo es requerido"),
    nacion: Yup.string().required("Este campo es requerido"),
    fnac: Yup.mixed()
      .required("Este campo es requerido")
      .test("menor21", "El familiar excede los 21 años", function (value) {
        return !EdadMenorVeintiuno(value);
      }),
    alta: Yup.mixed().required("Este campo es requerido"),
  });

  const initialValues = {
    paren: null,
    tdoc: null,
    nombre: "",
    estciv: null,
    fbaja: "",
    alta: "",
    mbaja: "",
    ndoc: "",
    cuil: "",
    sexo: null,
    fnac: "",
    nacion: null,
    zona: "",
    afiliado: "",
  };

  const formik = useFormik({
    enableReinitialize: true,
    validationSchema: validationSchemaCrear,
    initialValues: edicion || initialValues,
    onSubmit: async (values) => {
      setLoadingButton(true);
      setFormikLoading(true);
      try {
        if (modificar) {
          await axios.post(
            `${hosteo}/api/empadronamiento/beneficiarios/updateBenefFamiliar`,
            {
              benef: titular.nbenef,
              paren: selectedParentesco.id,
              tdoc: selectedTipoDocumento.id,
              ndoc: values.ndoc,
              nombre: values.nombre,
              sexo: selectedSexo?.id,
              estciv: selectedEstado?.id,
              fbaja: dateBaja ? reConvertirFormatoFecha(dateBaja) : null,
              fnac: reConvertirFormatoFecha(dateNac),
              cuil: values.cuil,
              alta: reConvertirFormatoFecha(dateAlta),
              nacion: nacion?.id,
              mbaja: mBaja ? mBaja.id : null,
            }
          );
          handleBuscar(guardarId, guardarTipo);
          setModificar(!modificar);
          limpiarCampos();
        } else {
          const response = await axios.post(
            `${hosteo}/api/empadronamiento/beneficiarios/sendBenefFamiliar`,
            {
              benef: titular.nbenef,
              paren: values.paren,
              tdoc: selectedTipoDocumento?.id,
              ndoc: values.ndoc,
              nombre: values.nombre,
              sexo: selectedSexo?.id,
              estciv: values.estciv,
              fnac: convertirFechaSend(values.fnac),
              fbaja: values.fbaja ? convertirFechaSend(values.fbaja) : null,
              zona: values.zona,
              cuil: values.cuil,
              alta: convertirFechaSend(values.alta),
              nacion: nacion?.id,
              mbaja: values.mbaja,
              afiliado: familiares.length + 1,
            }
          );
          handleBuscar(guardarId, guardarTipo);
          limpiarCampos();
        }
      } catch (error) {
        enqueueSnackbar(error.message || "No tienes conexión a internet", {
          variant: "error",
        });
      }
      setLoadingButton(false);
    },
  });

  const {
    handleSubmit,
    setFieldValue,
    values,
    touched,
    errors,
    getFieldProps,
    handleBlur,
  } = formik;

  // useEffect(() => {
  //   EdadMenorVeintiuno(values?.fnac);
  // }, [values?.paren, values?.fnac]);

  // -------------------Funcion para pasar de campo con el Enter-------------------

  const handleKeyDown = (event, nextInputId) => {
    if (event.key === "Enter") {
      if (nextInputId === 4) {
        datePickerRef.current.focus();
      } else if (nextInputId === 5) {
        datePickerRef2.current.focus();
      } else if (nextInputId === 10) {
        datePickerRef3.current.focus();
      } else if (nextInputId === 13) {
        console.log("Listo");
      } else {
        document.getElementById(nextInputId + 1).focus();
      }
    }
    console.log(nextInputId + 1);
  };

  const datePickerRef = useRef(null);
  const datePickerRef2 = useRef(null);
  const datePickerRef3 = useRef(null);

  const sumar21Anios = (fechaInicial) => {
    const fecha = new Date(fechaInicial);
    fecha.setFullYear(fecha.getFullYear() + 21); // suma 21 años
    console.log(fecha.toDateString(), "fechita");

    return fecha.toDateString(); // devuelve la fecha como string
  };

  const handleSetFechaBaja = (date) => {
    const fechaMas21 = sumar21Anios(date);
    setDateBaja(convertirFecha(fechaMas21));
    setFieldValue("fbaja", fechaMas21);
  };

  useEffect(() => {
    cuilTitular && handleBuscar(cuilTitular, tipoBusqueda);
  }, []);

  return (
    <>
      <FormikProvider value={formik}>
        <Form autoComplete="off" onSubmit={handleSubmit}>
          <Container maxWidth="xl">
            <Grid container justifyContent={"center"} display={"flex"}>
              <Typography variant="h3">
                Elija modo de busqueda de beneficiario familiar
              </Typography>
              <Grid md={12} sm={12} xs={12}>
                <Card sx={{ padding: 2, marginY: 3 }}>
                  <Grid md={12} container>
                    <RadioGroup
                      aria-labelledby="demo-row-radio-buttons-group-label"
                      value={tipoBusqueda}
                      onChange={(event, value) => {
                        setTipoBusqueda(event.target.value);
                      }}
                      name="row-radio-buttons-group"
                      row
                      sx={{
                        margin: "auto",
                        gap: "10px",
                        display: "flex",
                        flexWrap: "nowrap",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      <Grid item md={2}>
                        <FormControlLabel
                          value="cuil"
                          control={<Radio />}
                          label="CUIL"
                        />
                      </Grid>

                      <Grid item md={4}>
                        <FormControlLabel
                          value="documento"
                          control={<Radio />}
                          label="N° de documento"
                        />
                      </Grid>
                      <Grid item md={4}>
                        <FormControlLabel
                          value="nombre"
                          control={<Radio />}
                          label="Nombre parcial"
                        />
                      </Grid>
                      <Grid item md={4}>
                        <TextField
                          size="small"
                          id="search"
                          label={[<CustomLabel>Buscar</CustomLabel>]}
                          value={cuilTitular ? cuilTitular : datoBusqueda}
                          // value={cuilTitular ? cuilTitular : datoBusqueda}
                          onChange={(event, value) => {
                            setDatoBusqueda(event.target.value);
                          }}
                          variant="outlined"
                        />
                      </Grid>
                      <Grid>
                        <Button
                          onClick={() =>
                            handleBuscar(datoBusqueda, tipoBusqueda)
                          }
                          variant="contained"
                          color="primary"
                        >
                          Buscar
                        </Button>
                      </Grid>
                    </RadioGroup>
                  </Grid>
                </Card>
                {!formikLoading && isLoading ? (
                  <Grid container justifyContent="center" display="flex" mt={3}>
                    <CircularProgress />
                  </Grid>
                ) : titular?.cuil ? (
                  <>
                    <Card sx={{ paddingX: 2, paddingY: 1, marginBottom: 1 }}>
                      <Typography
                        variant="h4"
                        textAlign="center"
                        my={3}
                        component="p"
                      >
                        Datos de titular
                      </Typography>
                      <Grid
                        container
                        spacing={6}
                        justifyContent={"center"}
                        display={"flex"}
                      >
                        <Grid
                          item
                          sm={6}
                          md={4}
                          justifyContent={"center"}
                          display={"flex"}
                        >
                          <TextField
                            size="small"
                            label={[<CustomLabel>Nombre</CustomLabel>]}
                            value={titular.nombre}
                            InputProps={{
                              readOnly: true,
                            }}
                            variant="outlined"
                          />
                        </Grid>
                        <Grid
                          item
                          sm={6}
                          md={4}
                          justifyContent={"center"}
                          display={"flex"}
                        >
                          <TextField
                            size="small"
                            label={[<CustomLabel>N° Beneficiario</CustomLabel>]}
                            value={titular.nbenef}
                            InputProps={{
                              readOnly: true,
                            }}
                            variant="outlined"
                          />
                        </Grid>
                        <Grid
                          item
                          sm={6}
                          md={4}
                          justifyContent={"center"}
                          display={"flex"}
                        >
                          <TextField
                            size="small"
                            label={[<CustomLabel>N° afiliado</CustomLabel>]}
                            value={titular.afiliado}
                            InputProps={{
                              readOnly: true,
                            }}
                            variant="outlined"
                          />
                        </Grid>
                        <Grid
                          item
                          sm={6}
                          md={6}
                          justifyContent={"center"}
                          display={"flex"}
                        >
                          <TextField
                            size="small"
                            label={[<CustomLabel>Cuil</CustomLabel>]}
                            value={titular.cuil}
                            InputProps={{
                              readOnly: true,
                            }}
                            variant="outlined"
                          />
                        </Grid>
                        <Grid
                          item
                          sm={6}
                          md={6}
                          justifyContent={"center"}
                          display={"flex"}
                        >
                          <TextField
                            size="small"
                            label={[<CustomLabel>N° Documento</CustomLabel>]}
                            value={titular.numdoc}
                            InputProps={{
                              readOnly: true,
                            }}
                            variant="outlined"
                          />
                        </Grid>
                      </Grid>
                    </Card>
                    <Card>
                      <Typography
                        variant="h4"
                        textAlign="center"
                        my={3}
                        component="p"
                      >
                        Datos de familiares
                      </Typography>
                      <Box sx={{ p: 3 }}>
                        <DataGrid
                          disableRowSelectionOnClick
                          autoHeight
                          localeText={
                            esES.components.MuiDataGrid.defaultProps.localeText
                          }
                          rows={rowsWithId && familiares ? rowsWithId : []}
                          onPaginationModelChange={(row) =>
                            handlePaginationChange(row)
                          }
                          onPageChange={(newPage) => setCurrentPage(newPage)}
                          paginationModel={{
                            page: currentPage,
                            pageSize: pageSize,
                          }}
                          columns={columns}
                          initialState={{
                            pagination: {
                              paginationModel: { page: 0, pageSize: 25 },
                            },
                          }}
                          pageSizeOptions={[25, 50, 100]}
                        />
                      </Box>
                      <Grid
                        container
                        columnGap={8}
                        marginLeft={2}
                        justifyContent="center"
                        display="flex"
                      >
                        <Grid md={6}>
                          <Grid marginY={3}>
                            <Autocomplete
                              disabled={loadingButton}
                              name="paren"
                              disablePortal
                              autoHighlight
                              autoSelect
                              options={objOptions?.parentesco}
                              getOptionLabel={(option) => option.detalle}
                              getOptionValue={(option) => option.id}
                              value={selectedParentesco}
                              onChange={(event, value) => {
                                setSelectedParentesco(value);
                                setFieldValue("paren", value ? value.id : null);
                              }}
                              renderInput={(params) => (
                                <TextField
                                  {...params}
                                  error={Boolean(touched.paren && errors.paren)}
                                  helperText={touched.paren && errors.paren}
                                  label={[
                                    <CustomLabel>Parentesco</CustomLabel>,
                                  ]}
                                />
                              )}
                              id={1}
                              onKeyDown={(e) => handleKeyDown(e, 1)}
                            />
                          </Grid>
                          <Grid marginY={3}>
                            <Autocomplete
                              disabled={loadingButton}
                              name="tdoc"
                              disablePortal
                              autoHighlight
                              autoSelect
                              onBlur={handleBlur}
                              options={objOptions?.tipoDocum}
                              getOptionLabel={(option) =>
                                `${option.detalle} - ${option.id}`
                              }
                              getOptionValue={(option) => option.id}
                              value={selectedTipoDocumento}
                              onChange={(event, value) => {
                                setSelectedTipoDocumento(value);
                                setFieldValue("tdoc", value ? value.id : null);
                              }}
                              renderInput={(params) => (
                                <TextField
                                  sx={{ p: 0 }}
                                  error={Boolean(touched.tdoc && errors.tdoc)}
                                  helperText={touched.tdoc && errors.tdoc}
                                  {...params}
                                  label={[
                                    <CustomLabel>Tipo documento</CustomLabel>,
                                  ]}
                                />
                              )}
                              id={2}
                              onKeyDown={(e) => handleKeyDown(e, 2)}
                            />
                          </Grid>
                          <Grid marginY={3}>
                            <TextField
                              disabled={loadingButton}
                              name="nombre"
                              {...getFieldProps("nombre")}
                              value={formik.values.nombre}
                              onChange={(event, value) => {
                                setFieldValue(
                                  "nombre",
                                  event.target.value.toUpperCase()
                                );
                              }}
                              error={Boolean(touched.nombre && errors.nombre)}
                              helperText={touched.nombre && errors.nombre}
                              label={[<CustomLabel>Nombre</CustomLabel>]}
                              sx={{ width: "100%" }}
                              id={3}
                              onKeyDown={(e) => handleKeyDown(e, 3)}
                            />
                          </Grid>
                          <Grid marginY={3}>
                            <Autocomplete
                              disabled={loadingButton}
                              name="estciv"
                              disablePortal
                              autoHighlight
                              autoSelect
                              options={objOptions?.estado}
                              getOptionLabel={(option) => option.detalle}
                              getOptionValue={(option) => option.id}
                              value={selectedEstado}
                              onChange={(event, value) => {
                                setSelectedEstado(value);
                                setFieldValue(
                                  "estciv",
                                  value ? value.id : null
                                );
                              }}
                              renderInput={(params) => (
                                <TextField
                                  {...params}
                                  error={Boolean(
                                    touched.estciv && errors.estciv
                                  )}
                                  helperText={touched.estciv && errors.estciv}
                                  label={[
                                    <CustomLabel>Estado civil</CustomLabel>,
                                  ]}
                                />
                              )}
                              id={4}
                              onKeyDown={(e) => handleKeyDown(e, 4)}
                            />
                          </Grid>
                          <Grid marginY={3}>
                            <LocalizationProvider
                              dateAdapter={AdapterDateFns}
                              adapterLocale={es}
                            >
                              <DesktopDatePicker
                                disabled={loadingButton}
                                sx={{ width: "100%" }}
                                name="fbaja"
                                {...getFieldProps("fbaja")}
                                label={[
                                  <CustomLabel>Fecha de Baja</CustomLabel>,
                                ]}
                                value={dateBaja}
                                slotProps={{
                                  textField: {
                                    error: Boolean(
                                      touched.fbaja && errors.fbaja
                                    ),
                                    helperText: touched.fbaja && errors.fbaja,
                                  },
                                }}
                                onChange={(newValue) => {
                                  setFieldValue("fbaja", newValue);
                                  console.log(newValue);

                                  setDateBaja(convertirFecha(newValue));
                                }}
                                renderInput={(params) => (
                                  <TextField {...params} />
                                )}
                                id={5}
                                onAccept={() => {
                                  setTimeout(() => {
                                    datePickerRef2.current.focus();
                                  }, "50");
                                }}
                                inputRef={datePickerRef}
                              />
                            </LocalizationProvider>
                          </Grid>
                          <Grid marginY={3}>
                            <LocalizationProvider
                              dateAdapter={AdapterDateFns}
                              adapterLocale={es}
                            >
                              <DesktopDatePicker
                                disabled={loadingButton}
                                sx={{ width: "100%" }}
                                name="alta"
                                {...getFieldProps("alta")}
                                label={[
                                  <CustomLabel>Fecha de alta</CustomLabel>,
                                ]}
                                value={dateAlta}
                                slotProps={{
                                  textField: {
                                    error: Boolean(touched.alta && errors.alta),
                                    helperText: touched.alta && errors.alta,
                                  },
                                }}
                                onChange={(newValue) => {
                                  setDateAlta(convertirFecha(newValue));
                                  setFieldValue("alta", newValue);
                                }}
                                renderInput={(params) => (
                                  <TextField {...params} />
                                )}
                                id={6}
                                onAccept={() => {
                                  setTimeout(() => {
                                    document.getElementById(7).focus();
                                  }, "50");
                                }}
                                inputRef={datePickerRef2}
                              />
                            </LocalizationProvider>
                          </Grid>
                          <Grid marginY={3}>
                            <Autocomplete
                              disabled={loadingButton}
                              name="mbaja"
                              disablePortal
                              autoHighlight
                              autoSelect
                              options={objOptions?.baja}
                              getOptionLabel={(option) => option.detalle}
                              getOptionValue={(option) => option.id}
                              value={mBaja}
                              onChange={(event, value) => {
                                setMBaja(value);
                                setFieldValue("mbaja", value ? value.id : null);
                              }}
                              renderInput={(params) => (
                                <TextField
                                  error={Boolean(touched.mbaja && errors.mbaja)}
                                  helperText={touched.mbaja && errors.mbaja}
                                  {...params}
                                  label={[
                                    <CustomLabel>Motivo de baja</CustomLabel>,
                                  ]}
                                />
                              )}
                              id={7}
                              onKeyDown={(e) => handleKeyDown(e, 7)}
                            />
                          </Grid>
                        </Grid>
                        <Grid md={5}>
                          <Grid marginY={3}>
                            <TextField
                              disabled={loadingButton}
                              name="ndoc"
                              {...getFieldProps("ndoc")}
                              type="number"
                              value={values.ndoc}
                              onChange={(event, value) => {
                                setFieldValue("ndoc", event.target.value);
                              }}
                              error={Boolean(touched.ndoc && errors.ndoc)}
                              helperText={touched.ndoc && errors.ndoc}
                              label={<CustomLabel>N° documento</CustomLabel>}
                              sx={{ width: "100%" }}
                              id={8}
                              onKeyDown={(e) => handleKeyDown(e, 8)}
                            />
                          </Grid>
                          <Grid marginY={3}>
                            <TextField
                              disabled={loadingButton || modificar}
                              name="cuil"
                              {...getFieldProps("cuil")}
                              error={Boolean(touched.cuil && errors.cuil)}
                              helperText={touched.cuil && errors.cuil}
                              type="number"
                              value={values.cuil}
                              onChange={(event, value) => {
                                setFieldValue("cuil", event.target.value);
                              }}
                              label={<CustomLabel>CUIL</CustomLabel>}
                              sx={{ width: "100%" }}
                              id={9}
                              onKeyDown={(e) => handleKeyDown(e, 9)}
                            />
                          </Grid>
                          <Grid marginY={3}>
                            <Autocomplete
                              disabled={loadingButton}
                              name="sexo"
                              disablePortal
                              autoHighlight
                              autoSelect
                              options={sexo}
                              getOptionLabel={(option) => option.label}
                              getOptionValue={(option) => option.id}
                              value={selectedSexo}
                              onChange={(event, value) => {
                                setSelectedSexo(value);
                                setFieldValue("sexo", value ? value.id : null);
                              }}
                              renderInput={(params) => (
                                <TextField
                                  error={Boolean(touched.sexo && errors.sexo)}
                                  helperText={touched.sexo && errors.sexo}
                                  {...params}
                                  label={[<CustomLabel>Sexo</CustomLabel>]}
                                />
                              )}
                              id={10}
                              onKeyDown={(e) => handleKeyDown(e, 10)}
                            />
                          </Grid>
                          <Grid marginY={3}>
                            <LocalizationProvider
                              dateAdapter={AdapterDateFns}
                              adapterLocale={es}
                            >
                              <DesktopDatePicker
                                disabled={loadingButton}
                                name="nac"
                                {...getFieldProps("fnac")}
                                sx={{ width: "100%" }}
                                label={[
                                  <CustomLabel>
                                    Fecha de nacimiento
                                  </CustomLabel>,
                                ]}
                                value={dateNac}
                                onChange={(newValue) => {
                                  setFieldValue("fnac", newValue);
                                  setDateNac(convertirFecha(newValue));
                                  if (
                                    formik.values.paren === 3 ||
                                    formik.values.paren === 5
                                  ) {
                                    handleSetFechaBaja(newValue);
                                  }
                                }}
                                slotProps={{
                                  textField: {
                                    error: Boolean(touched.fnac && errors.fnac),
                                    helperText: touched.fnac && errors.fnac,
                                  },
                                }}
                                id={11}
                                onAccept={() => {
                                  setTimeout(() => {
                                    document.getElementById(12).focus();
                                  }, "50");
                                }}
                                inputRef={datePickerRef3}
                              />
                            </LocalizationProvider>
                          </Grid>
                          <Grid marginY={3}>
                            <Autocomplete
                              disabled={loadingButton}
                              name="nacion"
                              disablePortal
                              autoHighlight
                              autoSelect
                              options={objOptions?.nacionalidades}
                              getOptionLabel={(option) => option.detalle}
                              getOptionValue={(option) => option.id}
                              value={nacion}
                              onChange={(event, value) => {
                                setNacion(value);
                                setFieldValue(
                                  "nacion",
                                  value ? value.id : null
                                );
                              }}
                              renderInput={(params) => (
                                <TextField
                                  {...params}
                                  error={Boolean(
                                    touched.nacion && errors.nacion
                                  )}
                                  helperText={touched.nacion && errors.nacion}
                                  label={[<CustomLabel>Nación</CustomLabel>]}
                                />
                              )}
                              id={12}
                              onKeyDown={(e) => handleKeyDown(e, 12)}
                            />
                          </Grid>

                          <Grid marginY={4}>
                            <FormControlLabel
                              control={
                                <Checkbox
                                  checked={discapacitado}
                                  onChange={(event) => {
                                    setDiscapacitado(event.target.checked);
                                  }}
                                />
                              }
                              label={[<CustomLabel>Discapacitado</CustomLabel>]}
                            />
                          </Grid>
                        </Grid>
                      </Grid>
                    </Card>
                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "center",
                        mt: 2,
                        gap: 2,
                      }}
                    >
                      {modificar && (
                        <Button
                          sx={{ height: "100%" }}
                          onClick={limpiarCampos}
                          variant="contained"
                          color="error"
                        >
                          Cancelar
                        </Button>
                      )}
                      <LoadingButton
                        sx={{ height: "100%" }}
                        loading={loadingButton}
                        onClick={formik.handleSubmit}
                        variant="contained"
                      >
                        {modificar ? "Modificar datos" : "Aceptar"}
                      </LoadingButton>
                    </Box>
                  </>
                ) : (
                  ""
                )}
              </Grid>
            </Grid>
          </Container>
        </Form>
      </FormikProvider>
      <ModalAfiliados
        open={open}
        handleClose={handleClose}
        datos={titulares}
        setDatosBusqueda={setDatosBusqueda}
        handleBusqueda={handleBusqueda}
      />
    </>
  );
}
