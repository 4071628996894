import { Box, Button, Card, CircularProgress, TextField } from "@mui/material";
import { DataGrid, esES } from "@mui/x-data-grid";
import { useEffect, useState } from "react";
import axios from "../../../utils/axios";
import hosteo from "../../../utils/hosteo";
import { enqueueSnackbar } from "notistack";
import { HelperText } from "../HelperText";
import {
  MobileDateRangePicker,
  DesktopDateRangePicker,
} from "@material-ui/lab";
import { BrowserView, MobileView } from "react-device-detect";
import LocalizationProvider from "@material-ui/lab/LocalizationProvider";
import AdapterDateFns from "@material-ui/lab/AdapterDateFns";
import esLocale from "date-fns/locale/es";
import CustomLabel from "../../../theme/CustomLabel";
import { LoadingButton } from "@mui/lab";
import * as XLSX from "../../../../node_modules/xlsx/xlsx.js";
import DownloadIcon from "@mui/icons-material/Download";

const columns = [
  {
    field: "fecha",
    headerName: "Fecha",
    minWidth: 150,
    flex: 1,
  },
  {
    field: "codigo",
    headerName: "Codigo",
    minWidth: 150,
    flex: 1,
  },
  {
    field: "prestacion",
    headerName: "Prestacion",
    minWidth: 150,
    flex: 1,
  },
  {
    field: "ndoc",
    headerName: "DNI Beneficiario",
    minWidth: 150,
    flex: 1,
  },
  {
    field: "nombre",
    headerName: "Beneficiario",
    minWidth: 150,
    flex: 1,
  },

  {
    field: "medaut",
    headerName: "Medico Auditor",
    minWidth: 150,
    flex: 1,
  },
  {
    field: "medsol",
    headerName: "Medico solicitante",
    minWidth: 150,
    flex: 1,
    valueFormatter: (params) => (params.value === null ? "-" : params.value),
  },
  {
    field: "institu",
    headerName: "Institucion",
    minWidth: 150,
    flex: 1,
    valueFormatter: (params) => (params.value === null ? "-" : params.value),
  },
];

export default function Anticonceptivos() {
  const [datos, setDatos] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [value, setValue] = useState([null, null]);

  const obtenerDatos = async () => {
    setIsLoading(true);
    try {
      const response = await axios.post(
        `${hosteo}/api/empadronamiento/getAnticonceptivos`,
        {
          desde: value[0].toISOString().split("T")[0],
          hasta: value[1].toISOString().split("T")[0],
        }
      );
      setDatos(response.data);
    } catch (error) {
      enqueueSnackbar(error.message || "No tienes conexión a internet", {
        variant: "error",
      });
    } finally {
      setIsLoading(false);
    }
  };

  const rowsWithId =
    datos?.length > 0
      ? datos.map((item, index) => ({
          ...item,
          id: index + 1,
        }))
      : [];

  const generateAndDownloadExcel = () => {
    let nombre = "Pedido de anticonceptivos";

    const workbook = XLSX.utils.book_new();

    // Crea una nueva hoja de cálculo con datos
    const dia = new Date();
    const options = {
      timeZone: "America/Buenos_Aires",
      hour12: false,
      hour: "2-digit",
      minute: "2-digit",
      second: "2-digit",
    };

    // Encabezado original
    const encabezado = [
      [`Reporte de ${nombre}`],
      [
        `Generado a las ${dia
          .toLocaleTimeString("es-AR", options)
          .slice(0, 5)}hs del ${dia.toLocaleDateString()}`,
      ],
      [""],
    ];

    // Títulos de las columnas
    const columnTitles = columns.map((column) => column.headerName);

    // Unir encabezado original con nuevos títulos de columna
    const datosConTitulos = [...encabezado, columnTitles];

    // Agregar datos a la hoja de cálculo
    datos.forEach((item) => {
      const rowData = columns.map((column) => item[column.field]);

      datosConTitulos.push(rowData);
    });

    const worksheet = XLSX.utils.aoa_to_sheet(datosConTitulos);
    XLSX.utils.book_append_sheet(workbook, worksheet, nombre);
    const excelBuffer = XLSX.write(workbook, {
      bookType: "xlsx",
      type: "array",
    });
    const blob = new Blob([excelBuffer], {
      type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
    });
    const url = URL.createObjectURL(blob);
    const link = document.createElement("a");
    link.href = url;
    link.download = `${nombre}.xlsx`;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  return (
    <>
      <Box sx={{ mt: 2 }}>
        <HelperText
          message={"Muestra la cantidad de discapacitados por provincia."}
        />
      </Box>
      {isLoading ? (
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            my: 10,
          }}
        >
          <CircularProgress size={75} />
        </Box>
      ) : (
        <>
          <LocalizationProvider dateAdapter={AdapterDateFns} locale={esLocale}>
            <Card sx={{ my: 2, p: 3 }}>
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  gap: 1,
                  justifyContent: "center",
                  "@media (max-width: 700px)": {
                    flexDirection: "column",
                    alignItems: "center",
                    width: "100%",
                  },
                }}
              >
                <MobileView>
                  <MobileDateRangePicker
                    disabled={isLoading}
                    toolbarTitle="Seleccione entre fechas"
                    inputFormat="dd/MM/yyyy"
                    startText={<CustomLabel>Desde</CustomLabel>}
                    endText={<CustomLabel>Hasta</CustomLabel>}
                    value={value}
                    onChange={(newValue) => {
                      setValue(newValue);
                    }}
                    renderInput={(startProps, endProps) => (
                      <>
                        <TextField
                          name="desde"
                          autoComplete="off"
                          {...startProps}
                        />
                        <Box sx={{ mx: 2 }}> a </Box>
                        <TextField
                          name="hasta"
                          autoComplete="off"
                          {...endProps}
                        />
                      </>
                    )}
                  />
                </MobileView>
                <BrowserView>
                  <DesktopDateRangePicker
                    disabled={isLoading}
                    inputFormat="dd/MM/yyyy"
                    startText={<CustomLabel>Desde</CustomLabel>}
                    endText={<CustomLabel>Hasta</CustomLabel>}
                    value={value}
                    onChange={(newValue) => {
                      {
                        setValue(newValue);
                      }
                    }}
                    renderInput={(startProps, endProps) => (
                      <>
                        <TextField
                          name="desde"
                          autoComplete="off"
                          {...startProps}
                        />
                        <Box sx={{ mx: 2 }}> a </Box>
                        <TextField
                          name="hasta"
                          autoComplete="off"
                          {...endProps}
                        />
                      </>
                    )}
                  />
                </BrowserView>
              </Box>
              <Box sx={{ display: "flex", justifyContent: "center", mt: 2 }}>
                <LoadingButton
                  onClick={obtenerDatos}
                  variant="contained"
                  disabled={!value[0] || !value[1]}
                >
                  Buscar
                </LoadingButton>
              </Box>
            </Card>
            {datos?.length > 0 && (
              <Card sx={{ p: 3, mt: 2 }}>
                <Box sx={{ display: "flex", justifyContent: "center", my: 3 }}>
                  <Button
                    variant="contained"
                    color="success"
                    onClick={generateAndDownloadExcel}
                    endIcon={<DownloadIcon />}
                  >
                    EXCEL
                  </Button>
                </Box>
                <div>
                  <DataGrid
                    sx={{ padding: 1 }}
                    disableRowSelectionOnClick
                    autoHeight
                    localeText={
                      esES.components.MuiDataGrid.defaultProps.localeText
                    }
                    rows={rowsWithId}
                    columns={columns}
                    initialState={{
                      pagination: {
                        paginationModel: { page: 0, pageSize: 25 },
                      },
                    }}
                    pageSizeOptions={[5, 10, 25]}
                  />
                </div>
              </Card>
            )}
          </LocalizationProvider>
        </>
      )}
    </>
  );
}
