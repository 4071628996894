import { Box, Card, CircularProgress } from "@mui/material";
import { HelperText } from "../HelperText";
import { Button } from "@mui/material";
import { DataGrid, esES } from "@mui/x-data-grid";
import { useEffect, useState } from "react";
import { fCurrency } from "../../../utils/formatNumber";
import axios from "../../../utils/axios";
import hosteo from "../../../utils/hosteo";
import { enqueueSnackbar } from "notistack";
import * as XLSX from "../../../../node_modules/xlsx/xlsx.js";
import DownloadIcon from "@mui/icons-material/Download";

const columns = [
  {
    field: "codpro",
    headerName: "Codigo de provincia",
    minWidth: 150,
    flex: 1,
  },
  {
    field: "provincia",
    headerName: "Provincia",
    minWidth: 150,
    flex: 1,
  },
  {
    field: "ctitu",
    headerName: "Cantidad de titulares",
    minWidth: 150,
    flex: 1,
  },
  {
    field: "cFam",
    headerName: "Cantidad de familiares",
    minWidth: 150,
    flex: 1,
  },
  {
    field: "total",
    headerName: "Total",
    minWidth: 150,
    flex: 1,
  },
];

export default function BenefProvincia() {
  const [datos, setDatos] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  const obtenerDatos = async () => {
    setIsLoading(true);
    try {
      const response = await axios.get(
        `${hosteo}/api/empadronamiento/beneficiariosPorProvincia`
      );
      setDatos(response.data);
    } catch (error) {
      enqueueSnackbar(error.message || "No tienes conexión a internet", {
        variant: "error",
      });
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    obtenerDatos();
  }, []);

  const rowsWithId =
    datos?.length > 0
      ? datos.map((item, index) => ({
          ...item,
          id: index + 1,
        }))
      : [];

  const generateAndDownloadExcel = () => {
    let nombre = "Pedido de anticonceptivos";

    const workbook = XLSX.utils.book_new();

    // Crea una nueva hoja de cálculo con datos
    const dia = new Date();
    const options = {
      timeZone: "America/Buenos_Aires",
      hour12: false,
      hour: "2-digit",
      minute: "2-digit",
      second: "2-digit",
    };

    // Encabezado original
    const encabezado = [
      [`Reporte de ${nombre}`],

      [
        `Generado a las ${dia
          .toLocaleTimeString("es-AR", options)
          .slice(0, 5)}hs del ${dia.toLocaleDateString()}`,
      ],
      [""],
    ];

    // Títulos de las columnas
    const columnTitles = columns.map((column) => column.headerName);

    // Unir encabezado original con nuevos títulos de columna
    const datosConTitulos = [...encabezado, columnTitles];

    // Agregar datos a la hoja de cálculo
    datos.forEach((item) => {
      const rowData = columns.map((column) => item[column.field]);

      datosConTitulos.push(rowData);
    });

    const worksheet = XLSX.utils.aoa_to_sheet(datosConTitulos);
    XLSX.utils.book_append_sheet(workbook, worksheet, nombre);
    const excelBuffer = XLSX.write(workbook, {
      bookType: "xlsx",
      type: "array",
    });
    const blob = new Blob([excelBuffer], {
      type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
    });
    const url = URL.createObjectURL(blob);
    const link = document.createElement("a");
    link.href = url;
    link.download = `${nombre}.xlsx`;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  return (
    <>
      <Box sx={{ mt: 2 }}>
        <HelperText
          message={
            "Muestra la cantidad de titulares y familiares por provincia."
          }
        />
      </Box>
      {isLoading ? (
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            my: 10,
          }}
        >
          <CircularProgress size={75} />
        </Box>
      ) : (
        <Card sx={{ p: 3, mt: 2 }}>
          <Box sx={{ display: "flex", justifyContent: "center", my: 3 }}>
            <Button
              variant="contained"
              color="success"
              onClick={generateAndDownloadExcel}
              endIcon={<DownloadIcon />}
            >
              EXCEL
            </Button>
          </Box>
          <div>
            <DataGrid
              sx={{ padding: 1 }}
              disableRowSelectionOnClick
              autoHeight
              localeText={esES.components.MuiDataGrid.defaultProps.localeText}
              rows={rowsWithId}
              columns={columns}
              initialState={{
                pagination: { paginationModel: { page: 0, pageSize: 25 } },
              }}
              pageSizeOptions={[5, 10, 25]}
            />
          </div>
        </Card>
      )}
    </>
  );
}
