import Cabecera from "../../../../components/Cabecera";
import BenefProvincia from "../../../../components/Empadronamiento/CardsReportes/BenefProvincia";
const items = [
  { linkTo: "/dashboard/home", nombre: "Home" },
  { linkTo: "/dashboard/empadronamiento", nombre: "Empadronamiento" },
  {
    linkTo: "/dashboard/empadronamiento/beneficiarios/benefPorProvincia",
    nombre: "Reportes de beneficiacios",
  },
];

export default function BenefPorProvincia() {
  return (
    <>
      <Cabecera
        title="Beneficiarios por provincia"
        items={items}
        divider={false}
        helmet="Empadronamiento"
      />
      <BenefProvincia />
    </>
  );
}
